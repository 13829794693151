
// @ts-nocheck


export const localeCodes =  [
  "en",
  "vi"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en.js", load: () => import("../locales/en.js" /* webpackChunkName: "locale_D_58_D_58_App_Attractions_nuxt_app_locales_en_js" */), cache: true }],
  "vi": [{ key: "../locales/vi.js", load: () => import("../locales/vi.js" /* webpackChunkName: "locale_D_58_D_58_App_Attractions_nuxt_app_locales_vi_js" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "name": "English",
      "code": "en",
      "iso": "en",
      "icon": "/icons/en.svg",
      "files": [
        "locales/en.js"
      ]
    },
    {
      "name": "Tiếng việt",
      "code": "vi",
      "iso": "vi-VN",
      "icon": "/icons/vi.svg",
      "files": [
        "locales/vi.js"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "./locales",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "https://attractionsvietnam.com",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "forgot-password": {
      "en": "/forgot-password",
      "vi": "/quen-mat-khau"
    },
    "sign-in": {
      "en": "/sign-in",
      "vi": "/dang-nhap"
    },
    "sign-up": {
      "en": "/sign-up",
      "vi": "/dang-ky"
    },
    "articles/[slug]": {
      "en": "/articles/[slug]",
      "vi": "/bai-viet/[slug]"
    },
    "topic/[slug]": {
      "en": "/topic/[slug]",
      "vi": "/chu-de/[slug]"
    },
    "place/[slug]": {
      "en": "/place/[slug]",
      "vi": "/dia-diem/[slug]"
    },
    "place-hotels/[slug]": {
      "en": "/place-hotels/[slug]",
      "vi": "/dia-diem-khach-san/[slug]"
    },
    "attractions/[slug]": {
      "en": "/attractions/[slug]",
      "vi": "/diem-du-lich/[slug]"
    },
    "places": {
      "en": "/places",
      "vi": "/danh-sach-dia-diem"
    },
    "list-attractions": {
      "en": "/list-attractions",
      "vi": "/danh-sach-dia-diem-du-lich"
    },
    "author/[username]": {
      "en": "/author/[username]",
      "vi": "/tac-gia/[username]"
    },
    "contact-us": {
      "en": "/contact-us",
      "vi": "/lien-he"
    },
    "term-of-conditions": {
      "en": "/term-of-conditions",
      "vi": "/dieu-khoan-dich-vu"
    },
    "privacy-policy": {
      "en": "/privacy-policy",
      "vi": "/chinh-sach-bao-mat"
    },
    "verify": {
      "en": "/verify",
      "vi": "/thay-doi-mat-khau"
    },
    "dashboard/archived": {
      "en": "/dashboard/archived",
      "vi": "/bang-dieu-khien/luu-tru"
    },
    "dashboard/my-articles": {
      "en": "/dashboard/my-articles",
      "vi": "/bang-dieu-khien/danh-sach-bai-viet"
    },
    "dashboard/edit-profile": {
      "en": "/dashboard/edit-profile",
      "vi": "/bang-dieu-khien/chinh-sua-thong-tin"
    },
    "dashboard/submit-post": {
      "en": "/dashboard/submit-post",
      "vi": "/bang-dieu-khien/gui-bai-viet"
    },
    "about-us": {
      "en": "/about-us",
      "vi": "/ve-chung-toi"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "code": "en",
    "iso": "en",
    "icon": "/icons/en.svg",
    "files": [
      {
        "path": "locales/en.js"
      }
    ]
  },
  {
    "name": "Tiếng việt",
    "code": "vi",
    "iso": "vi-VN",
    "icon": "/icons/vi.svg",
    "files": [
      {
        "path": "locales/vi.js"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
