
import * as glideRuntime$pJzv1b3rDH from 'D:/App/Attractions/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/glide'
import * as storageRuntime$v3dbYoXWFU from 'D:/App/Attractions/nuxt-app/providers/storage.js'
import * as s3Runtime$NgEUFShn3n from 'D:/App/Attractions/nuxt-app/providers/s3.js'
import * as ipxRuntime$silYUc0DPz from 'D:/App/Attractions/nuxt-app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['glide']: { provider: glideRuntime$pJzv1b3rDH, defaults: {"baseURL":"https://storage.attractionsvietnam.com"} },
  ['storage']: { provider: storageRuntime$v3dbYoXWFU, defaults: {"baseURL":"https://storage.attractionsvietnam.com/storage","placeholder":"/icons/loading.svg"} },
  ['s3']: { provider: s3Runtime$NgEUFShn3n, defaults: {"baseURL":""} },
  ['ipx']: { provider: ipxRuntime$silYUc0DPz, defaults: {} }
}
        