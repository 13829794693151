import { default as _91slug_93M7q9J1WY1RMeta } from "D:/App/Attractions/nuxt-app/pages/[type]/[slug].vue?macro=true";
import { default as indexy7DBVRz3PhMeta } from "D:/App/Attractions/nuxt-app/pages/[type]/index.vue?macro=true";
import { default as about_45usagX6QAClPFMeta } from "D:/App/Attractions/nuxt-app/pages/about-us.vue?macro=true";
import { default as _91slug_93WdUb1zKfYYMeta } from "D:/App/Attractions/nuxt-app/pages/articles/[slug].vue?macro=true";
import { default as _91slug_93sblnKYk2jzMeta } from "D:/App/Attractions/nuxt-app/pages/attractions/[slug].vue?macro=true";
import { default as _91username_93kKnIWAfnLGMeta } from "D:/App/Attractions/nuxt-app/pages/author/[username].vue?macro=true";
import { default as indexNZkPycg9WuMeta } from "D:/App/Attractions/nuxt-app/pages/blog/index.vue?macro=true";
import { default as contact_45ushrUTfeHWvBMeta } from "D:/App/Attractions/nuxt-app/pages/contact-us.vue?macro=true";
import { default as archivedYjPiHg2ol9Meta } from "D:/App/Attractions/nuxt-app/pages/dashboard/archived.vue?macro=true";
import { default as edit_45profileJyGMblgNE2Meta } from "D:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue?macro=true";
import { default as indexxHhVchsNylMeta } from "D:/App/Attractions/nuxt-app/pages/dashboard/index.vue?macro=true";
import { default as my_45articlest8GBJKs0tOMeta } from "D:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue?macro=true";
import { default as submit_45posthW2A1XPK2SMeta } from "D:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue?macro=true";
import { default as forgot_45passwordtHOceIuatsMeta } from "D:/App/Attractions/nuxt-app/pages/forgot-password.vue?macro=true";
import { default as indexV9nvs2SiuXMeta } from "D:/App/Attractions/nuxt-app/pages/index.vue?macro=true";
import { default as list_45attractionsFyhXpLOpD9Meta } from "D:/App/Attractions/nuxt-app/pages/list-attractions.vue?macro=true";
import { default as _91slug_93lIUthT81LXMeta } from "D:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue?macro=true";
import { default as _91slug_939fRn7cFOVUMeta } from "D:/App/Attractions/nuxt-app/pages/place/[slug].vue?macro=true";
import { default as placesCQkAJvCdVuMeta } from "D:/App/Attractions/nuxt-app/pages/places.vue?macro=true";
import { default as privacy_45policyIyVa7ps0yMMeta } from "D:/App/Attractions/nuxt-app/pages/privacy-policy.vue?macro=true";
import { default as redirect9SVKvdPXQnMeta } from "D:/App/Attractions/nuxt-app/pages/redirect.vue?macro=true";
import { default as searchO4myI3NUatMeta } from "D:/App/Attractions/nuxt-app/pages/search.vue?macro=true";
import { default as sign_45inDdDnY6o2CTMeta } from "D:/App/Attractions/nuxt-app/pages/sign-in.vue?macro=true";
import { default as sign_45upC3lg9fzOyxMeta } from "D:/App/Attractions/nuxt-app/pages/sign-up.vue?macro=true";
import { default as _91slug_93GgsFiEsrLoMeta } from "D:/App/Attractions/nuxt-app/pages/tag/[slug].vue?macro=true";
import { default as term_45of_45conditionsdLLRCDmc2AMeta } from "D:/App/Attractions/nuxt-app/pages/term-of-conditions.vue?macro=true";
import { default as _91slug_93iasCPKwhgcMeta } from "D:/App/Attractions/nuxt-app/pages/topic/[slug].vue?macro=true";
import { default as verifyrseuKSZJRzMeta } from "D:/App/Attractions/nuxt-app/pages/verify.vue?macro=true";
export default [
  {
    name: _91slug_93M7q9J1WY1RMeta?.name ?? "type-slug___en",
    path: _91slug_93M7q9J1WY1RMeta?.path ?? "/:type()/:slug()",
    meta: _91slug_93M7q9J1WY1RMeta || {},
    alias: _91slug_93M7q9J1WY1RMeta?.alias || [],
    redirect: _91slug_93M7q9J1WY1RMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93M7q9J1WY1RMeta?.name ?? "type-slug___vi",
    path: _91slug_93M7q9J1WY1RMeta?.path ?? "/vi/:type()/:slug()",
    meta: _91slug_93M7q9J1WY1RMeta || {},
    alias: _91slug_93M7q9J1WY1RMeta?.alias || [],
    redirect: _91slug_93M7q9J1WY1RMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/[type]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexy7DBVRz3PhMeta?.name ?? "type___en",
    path: indexy7DBVRz3PhMeta?.path ?? "/:type()",
    meta: indexy7DBVRz3PhMeta || {},
    alias: indexy7DBVRz3PhMeta?.alias || [],
    redirect: indexy7DBVRz3PhMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: indexy7DBVRz3PhMeta?.name ?? "type___vi",
    path: indexy7DBVRz3PhMeta?.path ?? "/vi/:type()",
    meta: indexy7DBVRz3PhMeta || {},
    alias: indexy7DBVRz3PhMeta?.alias || [],
    redirect: indexy7DBVRz3PhMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/[type]/index.vue").then(m => m.default || m)
  },
  {
    name: about_45usagX6QAClPFMeta?.name ?? "about-us___en",
    path: about_45usagX6QAClPFMeta?.path ?? "/about-us",
    meta: about_45usagX6QAClPFMeta || {},
    alias: about_45usagX6QAClPFMeta?.alias || [],
    redirect: about_45usagX6QAClPFMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: about_45usagX6QAClPFMeta?.name ?? "about-us___vi",
    path: about_45usagX6QAClPFMeta?.path ?? "/vi/ve-chung-toi",
    meta: about_45usagX6QAClPFMeta || {},
    alias: about_45usagX6QAClPFMeta?.alias || [],
    redirect: about_45usagX6QAClPFMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WdUb1zKfYYMeta?.name ?? "articles-slug___en",
    path: _91slug_93WdUb1zKfYYMeta?.path ?? "/articles/:slug()",
    meta: _91slug_93WdUb1zKfYYMeta || {},
    alias: _91slug_93WdUb1zKfYYMeta?.alias || [],
    redirect: _91slug_93WdUb1zKfYYMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93WdUb1zKfYYMeta?.name ?? "articles-slug___vi",
    path: _91slug_93WdUb1zKfYYMeta?.path ?? "/vi/bai-viet/:slug()",
    meta: _91slug_93WdUb1zKfYYMeta || {},
    alias: _91slug_93WdUb1zKfYYMeta?.alias || [],
    redirect: _91slug_93WdUb1zKfYYMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/articles/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sblnKYk2jzMeta?.name ?? "attractions-slug___en",
    path: _91slug_93sblnKYk2jzMeta?.path ?? "/attractions/:slug()",
    meta: _91slug_93sblnKYk2jzMeta || {},
    alias: _91slug_93sblnKYk2jzMeta?.alias || [],
    redirect: _91slug_93sblnKYk2jzMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/attractions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93sblnKYk2jzMeta?.name ?? "attractions-slug___vi",
    path: _91slug_93sblnKYk2jzMeta?.path ?? "/vi/diem-du-lich/:slug()",
    meta: _91slug_93sblnKYk2jzMeta || {},
    alias: _91slug_93sblnKYk2jzMeta?.alias || [],
    redirect: _91slug_93sblnKYk2jzMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/attractions/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91username_93kKnIWAfnLGMeta?.name ?? "author-username___en",
    path: _91username_93kKnIWAfnLGMeta?.path ?? "/author/:username()",
    meta: _91username_93kKnIWAfnLGMeta || {},
    alias: _91username_93kKnIWAfnLGMeta?.alias || [],
    redirect: _91username_93kKnIWAfnLGMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/author/[username].vue").then(m => m.default || m)
  },
  {
    name: _91username_93kKnIWAfnLGMeta?.name ?? "author-username___vi",
    path: _91username_93kKnIWAfnLGMeta?.path ?? "/vi/tac-gia/:username()",
    meta: _91username_93kKnIWAfnLGMeta || {},
    alias: _91username_93kKnIWAfnLGMeta?.alias || [],
    redirect: _91username_93kKnIWAfnLGMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/author/[username].vue").then(m => m.default || m)
  },
  {
    name: indexNZkPycg9WuMeta?.name ?? "blog___en",
    path: indexNZkPycg9WuMeta?.path ?? "/blog",
    meta: indexNZkPycg9WuMeta || {},
    alias: indexNZkPycg9WuMeta?.alias || [],
    redirect: indexNZkPycg9WuMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexNZkPycg9WuMeta?.name ?? "blog___vi",
    path: indexNZkPycg9WuMeta?.path ?? "/vi/blog",
    meta: indexNZkPycg9WuMeta || {},
    alias: indexNZkPycg9WuMeta?.alias || [],
    redirect: indexNZkPycg9WuMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: contact_45ushrUTfeHWvBMeta?.name ?? "contact-us___en",
    path: contact_45ushrUTfeHWvBMeta?.path ?? "/contact-us",
    meta: contact_45ushrUTfeHWvBMeta || {},
    alias: contact_45ushrUTfeHWvBMeta?.alias || [],
    redirect: contact_45ushrUTfeHWvBMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: contact_45ushrUTfeHWvBMeta?.name ?? "contact-us___vi",
    path: contact_45ushrUTfeHWvBMeta?.path ?? "/vi/lien-he",
    meta: contact_45ushrUTfeHWvBMeta || {},
    alias: contact_45ushrUTfeHWvBMeta?.alias || [],
    redirect: contact_45ushrUTfeHWvBMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: archivedYjPiHg2ol9Meta?.name ?? "dashboard-archived___en",
    path: archivedYjPiHg2ol9Meta?.path ?? "/dashboard/archived",
    meta: archivedYjPiHg2ol9Meta || {},
    alias: archivedYjPiHg2ol9Meta?.alias || [],
    redirect: archivedYjPiHg2ol9Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/archived.vue").then(m => m.default || m)
  },
  {
    name: archivedYjPiHg2ol9Meta?.name ?? "dashboard-archived___vi",
    path: archivedYjPiHg2ol9Meta?.path ?? "/vi/bang-dieu-khien/luu-tru",
    meta: archivedYjPiHg2ol9Meta || {},
    alias: archivedYjPiHg2ol9Meta?.alias || [],
    redirect: archivedYjPiHg2ol9Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/archived.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileJyGMblgNE2Meta?.name ?? "dashboard-edit-profile___en",
    path: edit_45profileJyGMblgNE2Meta?.path ?? "/dashboard/edit-profile",
    meta: edit_45profileJyGMblgNE2Meta || {},
    alias: edit_45profileJyGMblgNE2Meta?.alias || [],
    redirect: edit_45profileJyGMblgNE2Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileJyGMblgNE2Meta?.name ?? "dashboard-edit-profile___vi",
    path: edit_45profileJyGMblgNE2Meta?.path ?? "/vi/bang-dieu-khien/chinh-sua-thong-tin",
    meta: edit_45profileJyGMblgNE2Meta || {},
    alias: edit_45profileJyGMblgNE2Meta?.alias || [],
    redirect: edit_45profileJyGMblgNE2Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: indexxHhVchsNylMeta?.name ?? "dashboard___en",
    path: indexxHhVchsNylMeta?.path ?? "/dashboard",
    meta: indexxHhVchsNylMeta || {},
    alias: indexxHhVchsNylMeta?.alias || [],
    redirect: indexxHhVchsNylMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: indexxHhVchsNylMeta?.name ?? "dashboard___vi",
    path: indexxHhVchsNylMeta?.path ?? "/vi/dashboard",
    meta: indexxHhVchsNylMeta || {},
    alias: indexxHhVchsNylMeta?.alias || [],
    redirect: indexxHhVchsNylMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: my_45articlest8GBJKs0tOMeta?.name ?? "dashboard-my-articles___en",
    path: my_45articlest8GBJKs0tOMeta?.path ?? "/dashboard/my-articles",
    meta: my_45articlest8GBJKs0tOMeta || {},
    alias: my_45articlest8GBJKs0tOMeta?.alias || [],
    redirect: my_45articlest8GBJKs0tOMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue").then(m => m.default || m)
  },
  {
    name: my_45articlest8GBJKs0tOMeta?.name ?? "dashboard-my-articles___vi",
    path: my_45articlest8GBJKs0tOMeta?.path ?? "/vi/bang-dieu-khien/danh-sach-bai-viet",
    meta: my_45articlest8GBJKs0tOMeta || {},
    alias: my_45articlest8GBJKs0tOMeta?.alias || [],
    redirect: my_45articlest8GBJKs0tOMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/my-articles.vue").then(m => m.default || m)
  },
  {
    name: submit_45posthW2A1XPK2SMeta?.name ?? "dashboard-submit-post___en",
    path: submit_45posthW2A1XPK2SMeta?.path ?? "/dashboard/submit-post",
    meta: submit_45posthW2A1XPK2SMeta || {},
    alias: submit_45posthW2A1XPK2SMeta?.alias || [],
    redirect: submit_45posthW2A1XPK2SMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue").then(m => m.default || m)
  },
  {
    name: submit_45posthW2A1XPK2SMeta?.name ?? "dashboard-submit-post___vi",
    path: submit_45posthW2A1XPK2SMeta?.path ?? "/vi/bang-dieu-khien/gui-bai-viet",
    meta: submit_45posthW2A1XPK2SMeta || {},
    alias: submit_45posthW2A1XPK2SMeta?.alias || [],
    redirect: submit_45posthW2A1XPK2SMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/dashboard/submit-post.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordtHOceIuatsMeta?.name ?? "forgot-password___en",
    path: forgot_45passwordtHOceIuatsMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordtHOceIuatsMeta || {},
    alias: forgot_45passwordtHOceIuatsMeta?.alias || [],
    redirect: forgot_45passwordtHOceIuatsMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordtHOceIuatsMeta?.name ?? "forgot-password___vi",
    path: forgot_45passwordtHOceIuatsMeta?.path ?? "/vi/quen-mat-khau",
    meta: forgot_45passwordtHOceIuatsMeta || {},
    alias: forgot_45passwordtHOceIuatsMeta?.alias || [],
    redirect: forgot_45passwordtHOceIuatsMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexV9nvs2SiuXMeta?.name ?? "index___en",
    path: indexV9nvs2SiuXMeta?.path ?? "/",
    meta: indexV9nvs2SiuXMeta || {},
    alias: indexV9nvs2SiuXMeta?.alias || [],
    redirect: indexV9nvs2SiuXMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexV9nvs2SiuXMeta?.name ?? "index___vi",
    path: indexV9nvs2SiuXMeta?.path ?? "/vi",
    meta: indexV9nvs2SiuXMeta || {},
    alias: indexV9nvs2SiuXMeta?.alias || [],
    redirect: indexV9nvs2SiuXMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: list_45attractionsFyhXpLOpD9Meta?.name ?? "list-attractions___en",
    path: list_45attractionsFyhXpLOpD9Meta?.path ?? "/list-attractions",
    meta: list_45attractionsFyhXpLOpD9Meta || {},
    alias: list_45attractionsFyhXpLOpD9Meta?.alias || [],
    redirect: list_45attractionsFyhXpLOpD9Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/list-attractions.vue").then(m => m.default || m)
  },
  {
    name: list_45attractionsFyhXpLOpD9Meta?.name ?? "list-attractions___vi",
    path: list_45attractionsFyhXpLOpD9Meta?.path ?? "/vi/danh-sach-dia-diem-du-lich",
    meta: list_45attractionsFyhXpLOpD9Meta || {},
    alias: list_45attractionsFyhXpLOpD9Meta?.alias || [],
    redirect: list_45attractionsFyhXpLOpD9Meta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/list-attractions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lIUthT81LXMeta?.name ?? "place-hotels-slug___en",
    path: _91slug_93lIUthT81LXMeta?.path ?? "/place-hotels/:slug()",
    meta: _91slug_93lIUthT81LXMeta || {},
    alias: _91slug_93lIUthT81LXMeta?.alias || [],
    redirect: _91slug_93lIUthT81LXMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93lIUthT81LXMeta?.name ?? "place-hotels-slug___vi",
    path: _91slug_93lIUthT81LXMeta?.path ?? "/vi/dia-diem-khach-san/:slug()",
    meta: _91slug_93lIUthT81LXMeta || {},
    alias: _91slug_93lIUthT81LXMeta?.alias || [],
    redirect: _91slug_93lIUthT81LXMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/place-hotels/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939fRn7cFOVUMeta?.name ?? "place-slug___en",
    path: _91slug_939fRn7cFOVUMeta?.path ?? "/place/:slug()",
    meta: _91slug_939fRn7cFOVUMeta || {},
    alias: _91slug_939fRn7cFOVUMeta?.alias || [],
    redirect: _91slug_939fRn7cFOVUMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/place/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_939fRn7cFOVUMeta?.name ?? "place-slug___vi",
    path: _91slug_939fRn7cFOVUMeta?.path ?? "/vi/dia-diem/:slug()",
    meta: _91slug_939fRn7cFOVUMeta || {},
    alias: _91slug_939fRn7cFOVUMeta?.alias || [],
    redirect: _91slug_939fRn7cFOVUMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/place/[slug].vue").then(m => m.default || m)
  },
  {
    name: placesCQkAJvCdVuMeta?.name ?? "places___en",
    path: placesCQkAJvCdVuMeta?.path ?? "/places",
    meta: placesCQkAJvCdVuMeta || {},
    alias: placesCQkAJvCdVuMeta?.alias || [],
    redirect: placesCQkAJvCdVuMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/places.vue").then(m => m.default || m)
  },
  {
    name: placesCQkAJvCdVuMeta?.name ?? "places___vi",
    path: placesCQkAJvCdVuMeta?.path ?? "/vi/danh-sach-dia-diem",
    meta: placesCQkAJvCdVuMeta || {},
    alias: placesCQkAJvCdVuMeta?.alias || [],
    redirect: placesCQkAJvCdVuMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/places.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyIyVa7ps0yMMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyIyVa7ps0yMMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyIyVa7ps0yMMeta || {},
    alias: privacy_45policyIyVa7ps0yMMeta?.alias || [],
    redirect: privacy_45policyIyVa7ps0yMMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyIyVa7ps0yMMeta?.name ?? "privacy-policy___vi",
    path: privacy_45policyIyVa7ps0yMMeta?.path ?? "/vi/chinh-sach-bao-mat",
    meta: privacy_45policyIyVa7ps0yMMeta || {},
    alias: privacy_45policyIyVa7ps0yMMeta?.alias || [],
    redirect: privacy_45policyIyVa7ps0yMMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: redirect9SVKvdPXQnMeta?.name ?? "redirect___en",
    path: redirect9SVKvdPXQnMeta?.path ?? "/redirect",
    meta: redirect9SVKvdPXQnMeta || {},
    alias: redirect9SVKvdPXQnMeta?.alias || [],
    redirect: redirect9SVKvdPXQnMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: redirect9SVKvdPXQnMeta?.name ?? "redirect___vi",
    path: redirect9SVKvdPXQnMeta?.path ?? "/vi/redirect",
    meta: redirect9SVKvdPXQnMeta || {},
    alias: redirect9SVKvdPXQnMeta?.alias || [],
    redirect: redirect9SVKvdPXQnMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/redirect.vue").then(m => m.default || m)
  },
  {
    name: searchO4myI3NUatMeta?.name ?? "search___en",
    path: searchO4myI3NUatMeta?.path ?? "/search",
    meta: searchO4myI3NUatMeta || {},
    alias: searchO4myI3NUatMeta?.alias || [],
    redirect: searchO4myI3NUatMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: searchO4myI3NUatMeta?.name ?? "search___vi",
    path: searchO4myI3NUatMeta?.path ?? "/vi/search",
    meta: searchO4myI3NUatMeta || {},
    alias: searchO4myI3NUatMeta?.alias || [],
    redirect: searchO4myI3NUatMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/search.vue").then(m => m.default || m)
  },
  {
    name: sign_45inDdDnY6o2CTMeta?.name ?? "sign-in___en",
    path: sign_45inDdDnY6o2CTMeta?.path ?? "/sign-in",
    meta: sign_45inDdDnY6o2CTMeta || {},
    alias: sign_45inDdDnY6o2CTMeta?.alias || [],
    redirect: sign_45inDdDnY6o2CTMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45inDdDnY6o2CTMeta?.name ?? "sign-in___vi",
    path: sign_45inDdDnY6o2CTMeta?.path ?? "/vi/dang-nhap",
    meta: sign_45inDdDnY6o2CTMeta || {},
    alias: sign_45inDdDnY6o2CTMeta?.alias || [],
    redirect: sign_45inDdDnY6o2CTMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upC3lg9fzOyxMeta?.name ?? "sign-up___en",
    path: sign_45upC3lg9fzOyxMeta?.path ?? "/sign-up",
    meta: sign_45upC3lg9fzOyxMeta || {},
    alias: sign_45upC3lg9fzOyxMeta?.alias || [],
    redirect: sign_45upC3lg9fzOyxMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: sign_45upC3lg9fzOyxMeta?.name ?? "sign-up___vi",
    path: sign_45upC3lg9fzOyxMeta?.path ?? "/vi/dang-ky",
    meta: sign_45upC3lg9fzOyxMeta || {},
    alias: sign_45upC3lg9fzOyxMeta?.alias || [],
    redirect: sign_45upC3lg9fzOyxMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GgsFiEsrLoMeta?.name ?? "tag-slug___en",
    path: _91slug_93GgsFiEsrLoMeta?.path ?? "/tag/:slug()",
    meta: _91slug_93GgsFiEsrLoMeta || {},
    alias: _91slug_93GgsFiEsrLoMeta?.alias || [],
    redirect: _91slug_93GgsFiEsrLoMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93GgsFiEsrLoMeta?.name ?? "tag-slug___vi",
    path: _91slug_93GgsFiEsrLoMeta?.path ?? "/vi/tag/:slug()",
    meta: _91slug_93GgsFiEsrLoMeta || {},
    alias: _91slug_93GgsFiEsrLoMeta?.alias || [],
    redirect: _91slug_93GgsFiEsrLoMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/tag/[slug].vue").then(m => m.default || m)
  },
  {
    name: term_45of_45conditionsdLLRCDmc2AMeta?.name ?? "term-of-conditions___en",
    path: term_45of_45conditionsdLLRCDmc2AMeta?.path ?? "/term-of-conditions",
    meta: term_45of_45conditionsdLLRCDmc2AMeta || {},
    alias: term_45of_45conditionsdLLRCDmc2AMeta?.alias || [],
    redirect: term_45of_45conditionsdLLRCDmc2AMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/term-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: term_45of_45conditionsdLLRCDmc2AMeta?.name ?? "term-of-conditions___vi",
    path: term_45of_45conditionsdLLRCDmc2AMeta?.path ?? "/vi/dieu-khoan-dich-vu",
    meta: term_45of_45conditionsdLLRCDmc2AMeta || {},
    alias: term_45of_45conditionsdLLRCDmc2AMeta?.alias || [],
    redirect: term_45of_45conditionsdLLRCDmc2AMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/term-of-conditions.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iasCPKwhgcMeta?.name ?? "topic-slug___en",
    path: _91slug_93iasCPKwhgcMeta?.path ?? "/topic/:slug()",
    meta: _91slug_93iasCPKwhgcMeta || {},
    alias: _91slug_93iasCPKwhgcMeta?.alias || [],
    redirect: _91slug_93iasCPKwhgcMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/topic/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93iasCPKwhgcMeta?.name ?? "topic-slug___vi",
    path: _91slug_93iasCPKwhgcMeta?.path ?? "/vi/chu-de/:slug()",
    meta: _91slug_93iasCPKwhgcMeta || {},
    alias: _91slug_93iasCPKwhgcMeta?.alias || [],
    redirect: _91slug_93iasCPKwhgcMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/topic/[slug].vue").then(m => m.default || m)
  },
  {
    name: verifyrseuKSZJRzMeta?.name ?? "verify___en",
    path: verifyrseuKSZJRzMeta?.path ?? "/verify",
    meta: verifyrseuKSZJRzMeta || {},
    alias: verifyrseuKSZJRzMeta?.alias || [],
    redirect: verifyrseuKSZJRzMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: verifyrseuKSZJRzMeta?.name ?? "verify___vi",
    path: verifyrseuKSZJRzMeta?.path ?? "/vi/thay-doi-mat-khau",
    meta: verifyrseuKSZJRzMeta || {},
    alias: verifyrseuKSZJRzMeta?.alias || [],
    redirect: verifyrseuKSZJRzMeta?.redirect || undefined,
    component: () => import("D:/App/Attractions/nuxt-app/pages/verify.vue").then(m => m.default || m)
  }
]